import { memo as Memo, useMemo, useRef, useCallback } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper'

//* GSAP
import gsap from 'gsap'

//* HOC
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Components
import Text from 'components/common/Text'
import Container from 'components/common/Container'
import ProductCard from 'components/common/ProductCard'
import Animate from 'components/common/Animate'
import Button from 'components/common/Button'

//* Style
import ProductsSliderSectionStyle from './style'

const ProductsSliderSection = Memo(({ products, title, button, winWidth }) => {
    //! Refs
    const slideRefs = useRef([])
    const sliderContRef = useRef()
    const cursorRef = useRef()
    const dragNumberSize = useRef(3)

    //! Slides
    const slides = useMemo(() => {
        return products.map((product, index) => (
            <SwiperSlide key={index}>
                {product.featured_image ? (
                    <div
                        className='ps-slide'
                        ref={(ref) => (slideRefs.current[index] = ref)}
                    >
                        <ProductCard
                            name={product.name}
                            description={product.types}
                            image={product.featured_image}
                            url={`/products/${product.slug}`}
                        />
                    </div>
                ) : null}
            </SwiperSlide>
        ))
    }, [products])

    //! Slides animation
    const slidesAnimation = useMemo(
        () => (
            <Animate
                startPoint='20% bottom'
                startX={'500%'}
                params={{ rightToLeft: [slideRefs] }}
            />
        ),
        []
    )

    //! Handle mouse move
    const handleMouseMove = useCallback(
        (e) => {
            if (products.length > dragNumberSize.current) {
                const sliderContRect = sliderContRef.current.getBoundingClientRect()
                const x = e.clientX - sliderContRect.left
                const y = e.clientY - sliderContRect.top

                gsap.to(cursorRef.current, { duration: 0.3, opacity: 1, left: x, top: y, scale: 1 })
            }
        },
        [products.length, dragNumberSize.current, cursorRef.current]
    )

    //! Handle mouse leave
    const handleMouseLeave = useCallback(() => {
        if (products.length > dragNumberSize.current) {
            gsap.to(cursorRef.current, { duration: 0.3, opacity: 0, scale: 0 })
        }
    }, [products.length, dragNumberSize.current, cursorRef.current])

    return (
        <ProductsSliderSectionStyle
            dragNumberSize={dragNumberSize.current}
            imagesQuantity={products.length}
        >
            <Container
                full
                first
            >
                {slidesAnimation}

                <Container>
                    <div className='ps-top-container'>
                        <Text
                            tag='h2'
                            text={title}
                            className={`minion3-medium uppercase h4 primary-color9 products-section-title`}
                        />

                        {button && winWidth >= 1024 && (
                            <Button
                                url={button.url}
                                text={button.text}
                                className='co-btn'
                            />
                        )}
                    </div>

                    <div
                        className='ps-slider-container'
                        onMouseMove={handleMouseMove}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div ref={sliderContRef} className="ps-slider-inner-container">
                            <Swiper
                                slidesPerView={'auto'}
                                draggable={true}
                                touchStartPreventDefault={false}
                                modules={[FreeMode]}
                                freeMode={true}
                            >
                                {slides}
                            </Swiper>

                            {products.length > dragNumberSize.current && (
                                <div
                                    ref={cursorRef}
                                    className='ps-drag-container'
                                >
                                    <Text
                                        tag='span'
                                        text='drag'
                                        className='ps-drag minion3-medium uppercase p p3'
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {button && winWidth < 1024 && (
                        <div className='ps-btn-cont'>
                            <Button
                                url={button.url}
                                text={button.text}
                                className='co-btn'
                            />
                        </div>
                    )}
                </Container>
            </Container>
        </ProductsSliderSectionStyle>
    )
})

export default withUIContext(ProductsSliderSection, ['winWidth'])
